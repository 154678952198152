<template>
  <div id="admin-page">
    <breadcrumb
      title="Admin"
      subtitle="Admin"
      sub-sub-title="Cache Management"
    />
    <div class="row lnv-main-card">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12"> 
                <div class="d-inline-flex justify-content-end w-100">
                  <BDropdown id="dropdown-1" :text="$ml.get('admin_cache_management')" class="m-md-2">
                    <BDropdownItem @click="clearCache('dashboard')">
                      <a v-text="$ml.get('admin_clean_dashboard')"></a>
                    </BDropdownItem>
                    <BDropdownItem @click="clearCache('targets')">
                      <a v-text="$ml.get('admin_clean_targets')"></a>
                    </BDropdownItem>
                    <BDropdownItem @click="clearCache('all')">
                      <a v-text="$ml.get('admin_clean_all')"></a>
                    </BDropdownItem>
                  </BDropdown>
                  <div class="m-md-2">
                    <button class="btn btn-sm btn-primary" @click="serialRematch" v-text="$ml.get('admin_unknown_serial_rematch')"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import breadcrumb from "../../components/breadcrumb/breadcrumb";
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import {ADMIN_CLEAN_CACHE_REQUEST, ADMIN_SERIAL_REMATCH_REQUEST} from "../../store/modules/admin/actions";

export default {
  name: "Admin",
  components: {
    breadcrumb: breadcrumb,
    BDropdownItem,
    BDropdown
  },
  methods: {
    clearCache(item) {
      this.$buefy.dialog.confirm({
        message: 
          `Do you want to clear<b class='red-heighlighted'> ${item} cache</b> ?`,
        onConfirm: () => {
          let pdata = {};
          pdata.cache = item;

          this.$store.dispatch(ADMIN_CLEAN_CACHE_REQUEST, pdata).then(res => {
            if (res.resultCode === 0) {
              this.$store.dispatch(NOTIFICATION_SUCCESS, {
                message: "Cleaned " + item + "cache",
                timeOut: true
              });
            }
          });
        }
      });
    },
    serialRematch(){
      this.$store.dispatch(ADMIN_SERIAL_REMATCH_REQUEST).then(res => {
        if (res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Unknown serial rematch is done",
            timeOut: true
          });
        }
      });
    }
  }
}
</script>